


































































































































import { defineComponent, onMounted, provide, Ref, ref, toRef, watch } from '@vue/composition-api'
import { useState, useActions } from '@/shared/mixins/helpers'
import { useI18n } from 'vue-i18n-composable'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import Table from '@/shared/components/Table.vue'
import { TableHeaderDTO } from '@/dto/tableHeaderDTO'
import { ControlAssessmentDTO } from '@/dto/backend-response/controlAssessmentsDTO'
import router from '@/router'
import moment from 'moment'
import utils from '@/shared/mixins/utils'
import generalData from '@/assets/data/general-data.json'
import controlQuery from '@/shared/queries/controlQueries'
import { Status, UserRole } from '@/shared/enum/general-enum'
import variables from '@/shared/variables'
import { TranslateResult } from 'vue-i18n'
import ControlAssessment from '@/components/control-assessment/ControlAssessment.vue'
import controlAssessmentsData from '@/assets/data/control-assessment-data.json'

export default defineComponent({
    components: { Table, ControlAssessment },
    props: {
        loggedInUser: {
            type: String,
        },
        validationMode: {
            default: false,
        },
    },

    setup(props) {
        const controlassessments = ref([])
        const lastRecordNo = ref(null)
        const controlAssessmentRecordNo = ref(null)
        const { t } = useI18n()
        const { language } = useState(['language'])
        const tableHeaders: Ref<Array<TableHeaderDTO>> = ref([])
        const loading = ref(false)
        const controlAssessmentResult = ref([])
        const { role } = useState(['role'])
        const noDataMessage: Ref<TranslateResult> = ref('')
        const displayControlAssessment = ref(false)
        const tableCmp = ref(null)
        const { menuOpen } = useState(['menuOpen'])
        const { SET_OPEN_MENU } = useActions(['SET_OPEN_MENU'])
        const { SET_LEFT_MENU_SIDEBAR } = useActions(['SET_LEFT_MENU_SIDEBAR'])
        const filterCount = ref(0)
        const filteredRecordCount = ref(0)
        const searchValue = ref('')
        const assessmentId = ref(null)
        const controlAssessDefaultFilters = ref({})
        const status: Ref = ref([])
        const totalPages = ref(0)
        const alertColor = ref(router.app.$route.query.alertColor)
        const validationMenu = ref(false)
        const firstRecordNo = ref(null)

        // Make validation mode available to children in sidebar
        provide('VIEW_VALIDATION_MODE', toRef(props, 'validationMode'))

        const setDefaultFilters = () => {
            controlAssessDefaultFilters.value = {}
            if (alertColor.value)
                controlAssessDefaultFilters.value['alertColorName'] = alertColor.value
            if (props.loggedInUser && role.value === UserRole.EMPLOYEE)
                controlAssessDefaultFilters.value['fexecutedBy'] = [props.loggedInUser]
            if (status.value)
                controlAssessDefaultFilters.value['status'] = Array.isArray(router.app.$route.query.status)
                ? [...router.app.$route.query.status]
                : router.app.$route.query.status ? [router.app.$route.query.status] : [];
        }
        const infomationOp = ref(null)

        const calculateEffective = (controlAssessment): string => {
            if (controlAssessment.overallConclusion === null) {
                return '-'
            }
            return controlAssessment.overallConclusion && controlAssessment.overallConclusion
        }

        // convert id values to name values
        const convertIdToNames = (state: number) => {
            const stateVal = utils.idToName(generalData.STATUS, state)

            return t(stateVal, language.value)
        }

        // set table headers
        const setTableHeaders = () => {
            tableHeaders.value = [
                {
                    header: t('NUMBER_SHORT', language.value),
                    sort: true,
                    fieldName: 'refId',
                    style: 'min-width: 210px;',
                    alert: true,
                },
                {
                    header: t('CONTROL_ASSESSMENTS_TABLE_COLUMN_CONTROL_NUMBER', language.value),
                    naturalCompare: true,
                    fieldName: 'controlNumber',
                    style: 'width: 200px;',
                },
                {
                    header: t('CONTROL_ASSESSMENTS_TABLE_COLUMN_TOPIC', language.value),
                    sort: true,
                    fieldName: 'topic',
                },
                {
                    header: t('CONTROL_ASSESSMENTS_TABLE_COLUMN_DESCRIPTION', language.value),
                    sort: true,
                    fieldName: 'description',
                    limit: 65,
                },
                {
                    header: t('CONTROL_ASSESSMENTS_TABLE_COLUMN_CATEGORY', language.value),
                    naturalCompare: true,
                    fieldName: 'category',
                },
                {
                    header: t('CONTROL_ASSESSMENTS_TABLE_COLUMN_EXECUTED_BY', language.value),
                    sort: true,
                    fieldName: 'fexecutedBy',
                },
                {
                    header: t('CONTROL_ASSESSMENTS_TABLE_COLUMN_EFFECTIVE', language.value),
                    sort: true,
                    fieldName: 'effective',
                    colorBoolDot: true,
                },
                {
                    header: t('CONTROL_ASSESSMENTS_TABLE_COLUMN_STATUS', language.value),
                    sort: true,
                    fieldName: 'status',
                },
                {
                    header: t('CONTROL_ASSESSMENTS_TABLE_COLUMN_APPROVED_ON', language.value),
                    sort: true,
                    fieldName: 'fapprovalDate',
                },
                {
                    header: t('CONTROL_ASSESSMENTS_TABLE_COLUMN_APPROVED_BY', language.value),
                    sort: true,
                    fieldName: 'fapprovedBy',
                },
            ]
        }

        // updating controlassessments array with additional fields and setting description content
        const toTableFormat = (cas: Array<ControlAssessmentDTO>) => {
            controlassessments.value = []
            cas.map((controlAssessment) => {
                let ca = {
                    id: controlAssessment.id,
                    no: null,
                    description: '',
                    category: '',
                    subcategory: '',
                    fexecutedBy: '',
                    effective: '',
                    fapprovalDate: '',
                    fapprovedBy: null,
                    status: null,
                    controlNumber: '',
                    topic: '',
                    color: '',
                    info: '',
                    infoKey: '',
                    alertColorName: '',
                }
                // creating a copy of object to hold formatted and extra attributes
                ca = Object.create(controlAssessment)
                ca.no = utils.padLeft('000', controlAssessment.refId)
                ca.description = controlAssessment.control.description
                ca.category = controlAssessment.control.category
                ca.subcategory = controlAssessment.control.subCategory
                ca.fexecutedBy =
                    controlAssessment.executedBy &&
                    controlAssessment.executedBy.firstName +
                        ' ' +
                        controlAssessment.executedBy.lastName
                ca.effective = calculateEffective(controlAssessment)
                ca.fapprovalDate = controlAssessment.validationDate
                    ? moment(controlAssessment.validationDate).format('DD-MM-YYYY')
                    : ''
                ca.fapprovedBy =
                    controlAssessment.validationBy &&
                    controlAssessment.validationBy.firstName +
                        ' ' +
                        controlAssessment.validationBy.lastName
                ca.status = convertIdToNames(controlAssessment.state)
                ca.controlNumber = controlAssessment.control.controlNumber
                ca.topic = controlAssessment.control.topic

                const alertsMenuType: string = props.validationMode ? 'menu-validation' : 'not-validation-mode'
                // get alert information
                ca.color = utils.getAlertInfo(alertsMenuType, controlAssessment).color
                ca.info = utils.getAlertInfo(alertsMenuType, controlAssessment).info
                ca.infoKey = utils.getAlertInfo(alertsMenuType, controlAssessment).infoKey
                // get the name of alert color code in order to use in filter
                ca.alertColorName = utils.getAlertInfo(alertsMenuType, controlAssessment).alertColorName

                controlassessments.value.push(ca)
            })
            controlassessments.value = controlassessments.value.sort((a, b) =>
                a.refId > b.refId ? 1 : -1
            )
        }

        const getControlAssessments = async (params) => {
            loading.value = true
            const pageNum = params.pageNum
            const query = `
                query{
                    controlassessments(perPage: ${variables.LIST_ITEMS_PER_PAGE}, pageNum: ${pageNum}, filter: {State: [${status.value}]}){
                        pageInfo{
                            totalPages
                            totalCount
                        }
                        items{
                            ${controlQuery.CONTROL_ASSESSMENTS_LIST}
                        }
                    }
                }
            `

            let result
            try {
                result = await apolloClient.getGraphqlData(query)
                loading.value = false
            } catch (err) {
                loading.value = false
                throw Error('Error while retrieving control assessments')
            }
            if (result) {
                const controlAssessmentsResut = result.data.controlassessments.items
                // total pages based on perPage value
                totalPages.value = result.data.controlassessments.pageInfo.totalPages
                // display totale records count as filteredRecordCount. This could change based on client's comments in future
                filteredRecordCount.value = result.data.controlassessments.pageInfo.totalCount
                // non-modified result (original)
                controlAssessmentResult.value = controlAssessmentsResut

                toTableFormat(controlAssessmentsResut)
            }
        }

        // go to control-assessment record
        const goToRecord = async (params) => {
            if (tableCmp.value) {
                /* get the index of selected details on `copyOfTableData`. 
            `controlassessments` cannot be use here as when the filter/ sort is in use, list is getting updated */
                const record = utils.getRecordByIndex(
                    tableCmp.value.copyOfTableData,
                    params.type,
                    params.no,
                    tableCmp.value
                )
                // find the record id (of previous/ next)
                assessmentId.value = controlassessments.value.find(
                    (assessment) => assessment.no === record.no
                ).id
                controlAssessmentRecordNo.value = record.no
            }
        }

        // update list after assessment is updated/created
        const updateListPostAssessment = async () => {
            await getControlAssessments({ pageNum: 1 })
        }

        const goToControlAssessment = (row) => {
            router.push({
                name: 'ControlAssessment',
                params: { controlId: row.control.id },
            })
        }

        // control assessment details slideout
        const openControlAssessmentPopup = async (id) => {
            displayControlAssessment.value = true
            controlAssessmentRecordNo.value = controlassessments.value.find(
                (assessment) => assessment.id == id
            ).no
            assessmentId.value = id
            // set last record no
            lastRecordNo.value = utils.getLastRecord(tableCmp.value.copyOfTableData)
            // set first record no
            firstRecordNo.value = tableCmp.value.copyOfTableData[0].no
        }

        // set no data message based on logged in user roles
        const setNoDataMessageForRoles = () => {
            let message
            switch (role.value) {
                case UserRole.EMPLOYEE:
                    message = 'NO_CONTROL_ASSESSMENTS_TABLE_DATA_AVAILABLE_FOR_USER_MESSAGE'
                    break
                case UserRole.VALIDATOR:
                    message =
                        'NO_VALIDATION_CONTROL_ASSESSMENTS_TABLE_DATA_AVAILABLE_FOR_USER_MESSAGE'
                    break
                default:
                    'No role found'
                    break
            }
            noDataMessage.value = t(message, language.value)
        }

        // display filter section
        const viewFilterSection = () => {
            tableCmp.value.setTableHeight()
        }

        // set filters count
        const setCount = (count) => {
            filterCount.value = count
        }

        // update table records count
        const updateRecordCount = (recordsCount) => {
            filteredRecordCount.value = recordsCount
        }

        // set searched records count
        const searchCount = (count) => {
            filterCount.value = 0
            filteredRecordCount.value = count
        }

        // close sidebar on escape key press
        const closeSidebarEsc = () => {
            // listening to escape key press
            document.addEventListener('keydown', (event) => {
                const secondSidebar = document.querySelector('.second-sidebar')
                if (event.key === 'Escape' && !secondSidebar) {
                    //close assessment sidebar if it's open and actionplan sidebar(second-sidebar) is closed
                    if (displayControlAssessment.value) {
                        displayControlAssessment.value = false
                    }
                }
            })
        }

        // open information overlay panel
        const toggleInformation = (event: object) => {
            infomationOp.value.toggle(event)
        }

        // set values based on query params
        const setQueryParamConfig = () => {
            // active menu based on menu headers
            validationMenu.value = props.validationMode
            if (validationMenu.value) {
                status.value = [Status.submitted, Status.approved, Status.declined]
            } else {
                status.value = []
            }
            // setting filter values from query params
            alertColor.value = router.app.$route.query.alertColor
        }

        // watch for language change from store and update table headers by calling setTableHeaders
        watch(language, () => {
            setTableHeaders()
            toTableFormat(controlassessments.value)
            setNoDataMessageForRoles()
        })

        watch(
            () => router.app.$route.query,
            async () => {
                setQueryParamConfig()
                await getControlAssessments({ pageNum: 1 })
                setDefaultFilters()
            },
            {
                immediate: true,
            }
        )

        onMounted(async () => {
            closeSidebarEsc()
            setTableHeaders()
            setNoDataMessageForRoles()
        })

        return {
            controlassessments,
            lastRecordNo,
            controlAssessmentRecordNo,
            t,
            language,
            utils,
            tableHeaders,
            goToControlAssessment,
            getControlAssessments,
            loading,
            role,
            variables,
            noDataMessage,
            openControlAssessmentPopup,
            displayControlAssessment,
            tableCmp,
            goToRecord,
            SET_OPEN_MENU,
            SET_LEFT_MENU_SIDEBAR,
            menuOpen,
            viewFilterSection,
            setCount,
            filterCount,
            filteredRecordCount,
            updateRecordCount,
            searchValue,
            searchCount,
            controlAssessmentsData,
            assessmentId,
            controlAssessDefaultFilters,
            toggleInformation,
            infomationOp,
            totalPages,
            updateListPostAssessment,
            validationMenu,
            firstRecordNo,
        }
    },
})
